function blokStartowy() {
   // Scrolled menu effect
   const $mainMenu = $('#main-navigation');
   const $heroSection = $('#hero');
   const $brandImg = $mainMenu.find('.navbar-brand img');
   const $pageMenu = $('.page-navigation');

   //const $itemLang = $('#item-lang');

   const makeMenuDefault = () => {
      $mainMenu.removeClass('navbar-light scrolled');
      $mainMenu.addClass('navbar-dark');

      $pageMenu.addClass('bg-primary');
      $brandImg.attr('src', $brandImg.data('default-img'));
   };

   const makeMenuScrolled = () => {
      $mainMenu.removeClass('navbar-dark');
      $mainMenu.removeClass('bg-primary');
      $mainMenu.addClass('navbar-light scrolled');

      $brandImg.attr('src', $brandImg.data('scrolled-img'));
   };

   if ($(this).scrollTop() > 10) {
      makeMenuScrolled();
   }

   // On scroll
   $(window).scroll(function() {
      if ($(this).scrollTop() > 10) {
         makeMenuScrolled();
      } else {
         // When navbar is collapsed
         if (!$('.navbar-collapse').hasClass('show')) {
            makeMenuDefault();
         }
      }
   });

   // Collapse events
   const $navbarCollapse = $('#navbar-collapse');

   // Show the collapsed links
   $navbarCollapse.on('show.bs.collapse', function() {
      if (!$mainMenu.hasClass('scrolled')) {
         makeMenuDefault();
      }
   });

   // Hide the collapsed links
   $navbarCollapse.on('hide.bs.collapse', function() {
      if (!$mainMenu.hasClass('scrolled')) {
         makeMenuScrolled();
      }
   });

   // ScrollSpy and smooth scroll
   $mainMenu.find('.nav-link-mainMenu').click(function(e) {
      e.preventDefault();
      // $xid = $(this);
      // console.log('to menu', $xid);
      const scrollTo = $(this).attr('href');
      $('#navbar-collapse').collapse('hide');
      $('html, body').animate(
         {
            scrollTop: $(scrollTo).offset().top - 60
         },
         300
      );
   });

   $heroSection.find('.hero-button').click(function(e) {
      e.preventDefault();

      const scrollTo = $(this).attr('href');
      //console.log('to guzik');
      // Collapse the menu if it's open
      $('#navbar-collapse').collapse('hide');

      $('html, body').animate(
         {
            scrollTop: $(scrollTo).offset().top - 60
         },
         300
      );
   });

   // $('#form-sample').on('submit', function(e) {
   //    e.preventDefault();
   //    var details = $('#form-sample').serialize();
   //    console.log('details', details);
   //    var formularz = $('#form-sample');

   //    //var form = document.getElementById('form_zmiana_danych');
   //    //var opcje =form.elements.branze;
   //    //console.log('opcje',opcje);
   //    //console.log('opcje.length',opcje.length);
   //    //console.log('opcje.value',opcje.value);
   //    //pętla po opcjach
   //    //for (var i=[0]; i<opcje.length; i++) {
   //    //}

   //    if (checkForm(formularz) === 0) {
   //       $.post('src/php/pages/contact/sample.php', details, function(data) {
   //          $('#form-contact-container').html(data);
   //          console.log('data', data);
   //       });
   //    } else window.scroll(0, 0);
   // });
}

$(function() {
   //const $itemLang = $('#item-lang');

   // $('a#item-lang').on('click', function(e) {
   //    e.preventDefault();
   //    console.log('ala ma kota 4');

   //    alert('Zmiana języka!');

   //    //$('#item-lang:contains("PL")').text('ENG');
   //    $this = $(this);
   //    console.log('xxx1', $this.text());
   //    console.log('this', $this);

   //    var jezyk = $this.text();
   //    console.log('jezyk', jezyk);

   //    $this.css({ color: '#fa0' });
   //    if (jezyk == 'ENG') $this.text('PL');
   //    else $this.text('ENG');
   //    console.log('xxx', $this.text());

   //    //location.reload();
   // });

   //console.log('ala ma kota 3');
   const $faturecard = $('.single-card-box');

   blokStartowy();

   // $faturecard.on('click', function(e) {
   //    e.preventDefault();
   //    ids = this.id;
   //    console.log('ids', ids);
   //    href = 'contact.php';
   //    $('html,body')
   //       .load(href, function() {
   //          // obsługa po wczytaniu
   //          const $mainMenu = $('#main-navigation');
   //          $mainMenu.removeClass('navbar-light scrolled');
   //          $mainMenu.addClass('navbar-dark');

   //          $pageMenu.addClass('bg-primary');
   //          $brandImg.attr('src', $brandImg.data('default-img'));

   //          blokStartowy();
   //          console.log('Wczytano treść');
   //       })
   //       .hide()
   //       .fadeIn(400); // koniec obsługi funkcji po wczytaniu
   // });
});

// var itemLang = document.querySelector('#item-lang');
// itemLang.addEventListener('click', function() {
//    // if (itemLang.style.color == 'red') itemLang.style.color = 'white';
//    // else itemLang.style.color = 'red';
//    console.log('ala ma kota 1');
//    if (itemLang.text == 'PL') itemLang.text = 'ENG';
//    else itemLang.text = 'PL';
//    //location.reload();
// });

// function isValidEmailAddress(emailAddress) {
//    var pattern = new RegExp(
//       /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
//    );
//    return pattern.test(emailAddress);
// }

// function checkForm(select_form) {
//    var elements = select_form.serializeArray();
//    var errMsg = 0;
//    var jezyk = $('input[name=lang]').attr('value');

//    $.each(elements, function(i, field) {
//       var ele = select_form.find('[name=' + field.name + ']');
//       ele.removeClass('error');
//       //console.log('ele',ele);
//       var errP = ele.next('span.errParagraph');
//       errP.hide();

//       if (ele.hasClass('req')) {
//          if (field.value == '') {
//             ele.addClass('error');
//             errP.show();
//             errMsg++;
//          } else if (field.name == '_email' && !isValidEmailAddress(field.value)) {
//             var errKomunikat = document.getElementById('err_email');
//             if (jezyk == 'an')
//                errKomunikat.innerHTML = '</br>Please enter correct e-mail address!</br>';
//             else errKomunikat.innerHTML = '</br>Proszę wprowadzić poprawny adres e-mail!</br>';
//             ele.addClass('error');
//             errP.show();
//             errMsg++;
//          }
//       }
//    });

//    return errMsg;
// }
